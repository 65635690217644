import React, { useEffect, useState, lazy, useMemo, Suspense } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch, useSelector } from "react-redux";
// Impact Ui
import { Tabs } from "impact-ui";

let LoadingOverlay = lazy(() => import("../../../componentsV2/LoadingOverlay"));
let ComponentResolver = lazy(() => import("./ComponentResolver"));

// Material Ui components
let Box = lazy(() => import("@mui/material/Box"));
let Grid = lazy(() => import("@mui/material/Grid"));

let getMultipleModelsData = lazy(() =>
  import("../action").then((module) => ({
    default: module.getMultipleModelsData,
  }))
);

const TabResolver = (props) => {
  const tabs = props.pageObject.tabs.filter((val) => {
    return val.showOnClickButton === undefined;
  });
  const [tabsList, setTabsList] = useState([]);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [otherComponentsRequired, setOtherComponentsRequired] = useState(
    props.pageObject.components && props.pageObject.components.length > 0
  );

  const activeTabs = useMemo(() => {
    return props.pageObject.tabs.filter(
      (item) => !item.components && item?.components?.length > 0
    );
  }, [props.pageObject.tabs]);

  const [tabValue, setTabValue] = useState(
    activeTabs[props.pageObject.initialSelectedIndex || 0]?.label
  ); // save current tab value

  // for impact Ui components props
  const [tabData, setTabData] = useState(
    props.pageObject.tabs.map((item) => {
      return {
        label: item.label,
        value: item.label,
        disabled: !item.components || item?.components?.length == 0,
        element: (
          <>
            {tabValue && (
              <Suspense fallback={<LoadingOverlay loader={true} />}>
                <ComponentResolver
                  pageObject={{ components: item.components }}
                />
              </Suspense>
            )}
          </>
        ),
        // element: (
        //   <ComponentResolver pageObject={{ components: item.components }} />
        // ),
      };
    })
  ); // to save tabs data

  const {
    dependentInfo,
    tabRedirect,
    showActiveTab,
    tabIndexValue,
    redirectedTabIndexValue,
    dynamicTabContent,
    newDynamicTabIndexValue,
    tabList,
    removableTabs,
  } = useSelector((state) => state.home);

  useEffect(() => {
    if (tabRedirect["isDependentOn"]) {
      const newTabsList = tabs.filter((obj) => {
        return (
          obj["dependentOn"] === undefined ||
          dependentInfo[`${obj[`dependentOn`]}`] === true
        );
      });
      setTabsList([...newTabsList]);
      // const newTabs = tabsList.filter(obj=> ( ( !obj['dependentOn'] || obj['dependentOn'] === dependentInfo[`${obj[`dependentOn`]}`] ))
    } else {
      setTabsList([...tabs]);
    }
    setOtherComponentsRequired(
      props.pageObject.otherComponentsRequired || false
    );
    if (props.pageObject && props.pageObject["default_api_calls"]) {
      getMultipleModelsData({
        model_names: props.pageObject["default_api_calls"],
      }).then((res) => {
        dispatch({
          type: "TABLE_DATA",
          payload: res,
        });
      });
    }
  }, [props.pageObject.tabs]);

  useEffect(() => {
    if (props.pageObject.initialSelectedIndex) {
      setTabIndex(Number(props.pageObject.initialSelectedIndex));
    }
    if (redirectedTabIndexValue && props.pageObject.redirectionOnClick)
      setTabIndex(Number(redirectedTabIndexValue));
  }, [
    redirectedTabIndexValue,
    tabsList,
    props.pageObject.initialSelectedIndex,
  ]);

  useEffect(() => {
    if (Object.keys(tabRedirect).length > 0) {
      if (
        tabsList.map((obj) => obj.label).includes(tabRedirect.parentTabValue)
      ) {
        setTabIndex(tabRedirect.parentTabIndex);
        setTabValue(tabRedirect.parentTabValue);
      }
      if (
        tabsList.map((obj) => obj.label).includes(tabRedirect.activeTabValue)
      ) {
        setTabIndex(tabRedirect.activeTabIndex);
      }
    }
  }, [tabRedirect, tabsList, tabData]);

  useEffect(() => {
    if (showActiveTab) {
      setTabsList(props.pageObject.tabs);
      setTabIndex(Number(tabIndexValue));
      setTabValue(tabIndexValue);
    }
  }, [showActiveTab]);

  const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  let tabListValue = useMemo(() => cloneDeep(tabsList), [tabsList]);
  useEffect(() => {
    if (dynamicTabContent) {
      if (tabListValue[tabListValue.length - 2] !== undefined) {
        let newTabValue =
          Number(tabListValue[tabListValue.length - 2]?.key) + 1;
        let newTab = {
          label: "Scenario" + newTabValue,
          showOnClickButton: true,
          title: "Scenario " + newTabValue,
          value: "Scenario " + newTabValue,
          key: newTabValue,
          components: tabListValue[tabListValue.length - 2]?.components,
        };
        let newTabList = insert(tabListValue, tabListValue.length - 1, newTab);
        setTabIndex(Number(newTabValue));
        setTabsList(newTabList);
      }
    }
  }, [dynamicTabContent, newDynamicTabIndexValue]);

  // this function updates and populate hook accordingly to our object key-value requirement
  useEffect(() => {
    let tabs =
      removableTabs &&
      tabList[`${props.pageObject?.unique_tab_key}`]?.length > 0
        ? tabList[`${props.pageObject?.unique_tab_key}`]
        : tabsList;
    let result = tabs
      ?.filter((obj) => {
        return (
          obj["dependentOn"] === undefined ||
          dependentInfo[`${obj[`dependentOn`]}`] === true
        );
      })
      ?.map((item, index) => {
        return {
          label: item.label,
          value: item.label,
          disabled:
            !item.components || item.components.length == 0 ? true : false,
          element: (
            <ComponentResolver pageObject={{ components: item.components }} />
          ),
        };
      });
    setOtherComponentsRequired(
      props.pageObject.components && props.pageObject.components.length > 0
    );
    setTabData(result);
  }, [
    tabsList,
    dependentInfo,
    removableTabs,
    tabList[`${props.pageObject?.unique_tab_key}`],
  ]);

  // this functions helps in changing tab
  const handleImpactTabChange = (value) => {
    setTabValue(value);

    if (tabRedirect && Object.keys(tabRedirect).length > 0)
      dispatch({
        type: "RESET_TAB_REDIRECT",
      });

    let params = {};
    props.pageObject.tabs.map((obj, index) => {
      if (obj.enableKeys) {
        obj.enableKeys.map((key) => {
          params[key] = true;
        });
      }
      if (obj.disableKeys) {
        obj.disableKeys.map((key) => {
          params[key] = false;
        });
      }
    });
    if (props.pageObject?.action && props.pageObject?.action?.key === value) {
      params[`${props.pageObject?.action?.key}`] = true;
    }
    dispatch({
      type: "DEPENDENT_COMPONENTS",
      payload: params,
    });
  };

  useEffect(() => {
    let activeTab = tabValue;
    if (props?.pageObject?.enableSelectiveRender) {
      let params = {};
      if (
        dependentInfo?.[`${activeTab}`] === undefined ||
        dependentInfo?.[`${activeTab}`] === false
      ) {
        params[activeTab] = true;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
    }
  }, [tabValue]);

  useEffect(() => {
    if (
      props.pageObject?.removableTabsRequired &&
      props?.pageObject?.unique_tab_key
    ) {
      let params = {};
      params[`${props.pageObject?.unique_tab_key}`] = props?.pageObject?.tabs;
      dispatch({
        type: "SET_TABLIST",
        payload: params,
      });
    }
  }, []);

  return (
    <Box sx={{ width: "100%", ...props.pageObject.style }}>
      {otherComponentsRequired ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ padding: "10px 0", position: "relative" }}>
                {tabData.length > 0 ? (
                  <Tabs
                    tabs={tabData}
                    value={tabValue}
                    onChange={(value) => {
                      handleImpactTabChange(value);
                    }}
                  />
                ) : (
                  <LoadingOverlay loader={true} />
                )}
                <div className="tabs-other-component">
                  <ComponentResolver
                    pageObject={{ components: props.pageObject.components }}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Box sx={{ padding: "10px 0", position: "relative" }}>
              {tabData.length > 0 ? (
                <Tabs
                  tabs={tabData}
                  value={tabValue}
                  onChange={(value) => {
                    handleImpactTabChange(value);
                  }}
                />
              ) : (
                <LoadingOverlay loader={true} />
              )}
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default TabResolver;
